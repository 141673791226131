<template>
  <div>
    <appr-main :isShow="isShow" :isYongYouFlag="isYongYouFlag" @draftState="draftState"></appr-main>
  </div>
</template>

<script>
import apprMain from '@/views/component/apprCommon/apprMain';
export default {
  name: 'CopyEditAppr',
  components: {
    apprMain
  },
  props: {
    isShow: {
      type: Boolean,
      required: true
    },
    isYongYouFlag: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    draftState() {
      this.$emit('draftState');
    }
  }
};
</script>

<style scoped></style>
