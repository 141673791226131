import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const oinvAPI = {
  getOinvs: topic.oinvTopic + '/get_oinvs',
  getSgins: topic.oinvTopic + '/get_sgins',
  getOinvById: topic.oinvTopic + '/get_oinv_by_id',
  addOinv: topic.oinvTopic + '/add_oinv',
  editOinv: topic.oinvTopic + '/edit_oinv',
  deleteOinvByIds: topic.oinvTopic + '/delete_oinv_by_ids',
  getSconProdList: topic.oinvTopic + '/getSconProdList',
  getRichErpOinvList: params => getNoCatch(`${topic.oinvTopic}/getRichErpOinvList`, params),
  addRichErpOinvList: params => postNoCatch(`${topic.oinvTopic}/addRichErpOinvList`, params),
  exportExcel: params => getNoCatch(`${topic.oinvTopic}/exportExcel`, params)
};
